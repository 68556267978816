<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="">
        <v-img :src="require('../assets/logo.jpg')" height=200 contain />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="11">
        <p class="subheading font-weight-regular">
          PremelCake はシチリアの伝統的なスイーツ「カッサータ」をベースに、日本人の味覚に合わせて作った生クリームケーキ。カッサータは、チーズが入ったクリームに、ナッツとフルーツを入れて冷やし固めたもので、日本ではあまり馴染みがありませんが、ほどよい甘みと華やかな見た目で、おもてなしにもぴったりのスイーツです。
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="n in 3"
        :key="n"
        class="d-flex child-flex"
        cols="4"
      >
        <v-img :src="require(`../assets/00${n}.jpg`)" aspect-ratio="1" class="grey lighten-2">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="">
        <h1 class="">お届け先入力</h1>
        <v-form ref="form" method="POST" name="order" action="/thanks.html" netlify>
          <v-text-field v-show="false" v-model="title" name="form-name" />
          <div v-show="false">
            <label for="bot">スパムでない場合は空欄</label>
            <input id="bot" type="text" name="bot-field" />
          </div>
          <v-row dense>
            <v-col sm="6">
              <v-text-field
                v-model="code"
                name="code"
                label="コード"
                hint="11桁のコードを半角英数字で入力してください。"
                :rules="[() => !!code || '入力必須項目です。']"
                maxlength="11"
                counter
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col md="4">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    name="date"
                    label="お届け希望日"
                    hint="注文日から中7日以降、中30日以内で指定可能"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  locale="ja-jp"
                  :first-day-of-week="1"
                  :day-format="date => new Date(date).getDate()"
                  :allowed-dates="allowedDates"
                  @input="menu2 = false"
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col md="4">
              <v-select
                v-model="time"
                name="time"
                label="希望時刻"
                prepend-inner-icon="mdi-clock-outline"
                :items="times"
                :rules="[() => !!times || '入力必須項目です。']"
                required
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col sm="4">
              <v-text-field
                v-model="lastname"
                name="lastname"
                label="姓"
                :rules="[() => !!lastname || '入力必須項目です。']"
                required
                outlined
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="firstname"
                name="firstname"
                label="名"
                :rules="[() => !!firstname || '入力必須項目です。']"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col sm="3">
              <v-text-field
                v-model="zip"
                name="zip"
                label="郵便番号"
                :rules="[() => !!zip || '入力必須項目です。']"
                ref="zip"
                required
                outlined
                maxlength="8"
                placeholder="100-0001"
              ></v-text-field>
            </v-col>
            <v-col sm="3">
              <v-select
                v-model="prefecture"
                name="prefecture"
                label="都道府県"
                :items="prefectures"
                :rules="[() => !!prefecture || '入力必須項目です。']"
                required
                outlined
              ></v-select>
            </v-col>
            <v-col sm="6">
              <v-text-field
                v-model="municipality"
                name="municipality"
                label="市区町村"
                :rules="[() => !!municipality || '入力必須項目です。']"
                required
                outlined
                placeholder="中央区"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col sm="12">
              <v-text-field
                v-model="address"
                name="address"
                label="番地以下"
                placeholder="中央1-1-1"
                required
                outlined
                :rules="[() => !!address || '入力必須項目です。']"
              ></v-text-field>
            </v-col>
          </v-row>
           <v-row dense>
            <v-col sm="12">
              <v-text-field
                v-model="building"
                name="building"
                label="建物名・部屋番号等"
                hint=""
                placeholder="中央ビルディング 707号室"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col sm="4">
              <v-text-field
                v-model="tel"
                name="tel"
                label="電話番号"
                hint="半角数字で入力してください。"
                placeholder="000-0000-0000"
                maxlength="13"
                required
                outlined
                :rules="[() => !!tel || '入力必須項目です。']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col sm="8">
              <v-text-field
                v-model="email"
                name="email"
                label="メールアドレス"
                hint="半角英数字で入力してください。"
                placeholder="user@example.jp"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-checkbox v-model="optin" name="optin" value="オプトインOK" label="今回入力頂いた情報を今後新商品のご案内等に利用してもよいですか？"></v-checkbox>
          </v-row>
          <v-row justify="center">
            <v-col>
              <v-btn class="mr-4" type="submit" @click="confirmForm">送信する</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p><a href="https://delivershop.net/pages/inquiry">お問い合わせ</a></p>
      </v-col>
    </v-row>
  </v-container>
</template>
 
<script>
  export default {
    mounted () {
      const title = "「プレメルケーキ」お届け先入力サイト PremelCake"
      document.title = title
//      document.querySelector("meta[property='og:title']").setAttribute('content', title)
//      const description = "説明文"
//      document.querySelector("meta[name='description']").setAttribute('content', description)
//      document.querySelector("meta[property='og:description']").setAttribute('content', description)
    },
    data () {
      return {
        title: 'order',
        items: [
          { src: require('../assets/001.jpg') },
          { src: require('../assets/002.jpg') },
          { src: require('../assets/003.jpg') },
        ],
        dialog: false,
        code: '',
        date: '',
        time: '',
        lastname: '',
        firstname: '',
        zip: '',
        prefecture: '',
        municipality: '',
        address: '',
        building: '',
        tel: '',
        email: '',
        menu2: false,
        times: [
          '午前中',
          '14-16時',
          '16-18時',
          '18-20時',
          '19-21時'
        ],
        prefectures: [
          '北海道',
          '青森県',
          '岩手県',
          '宮城県',
          '秋田県',
          '山形県',
          '福島県',
          '茨城県',
          '栃木県',
          '群馬県',
          '埼玉県',
          '千葉県',
          '東京都',
          '神奈川県',
          '新潟県',
          '富山県',
          '石川県',
          '福井県',
          '山梨県',
          '長野県',
          '岐阜県',
          '静岡県',
          '愛知県',
          '三重県',
          '滋賀県',
          '京都府',
          '大阪府',
          '兵庫県',
          '奈良県',
          '和歌山県',
          '鳥取県',
          '島根県',
          '岡山県',
          '広島県',
          '山口県',
          '徳島県',
          '香川県',
          '愛媛県',
          '高知県',
          '福岡県',
          '佐賀県',
          '長崎県',
          '熊本県',
          '大分県',
          '宮崎県',
          '鹿児島県',
          '沖縄県'
        ],
        optin: 'オプトインOK',
      }
    },
    methods: {
      allowedDates: function (val) {
        let today = new Date() 
        today = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 8
        )
        let maxAllowedDay = new Date()
        maxAllowedDay.setDate(
          today.getDate()
        )
        maxAllowedDay = new Date(
          maxAllowedDay.getFullYear(),
          maxAllowedDay.getMonth(),
          maxAllowedDay.getDate() + 53
        )
        return today <= new Date(val) && new Date(val) <= maxAllowedDay
      },
      async confirmForm(e) {
        e.preventDefault();
        let isFilled = this.checkRequired(e);
        if (!isFilled) {
          return;
        }
        let select = await this.getTableSelect();
        let firstPage = await select.firstPage();
        let codeStatus;
        await firstPage.findLast((record) => {
          codeStatus = record.get('ステータス')
        });
        let validStatus = this.checkStatus(e,codeStatus);
        if (!validStatus) {
          return;
        }
        var isConfirm = this.isConfirmed(e);
        if (!isConfirm) {
          return;
        }
        this.$refs.form.$el.submit();
      },
      checkStatus: function (e,codeStatus) {
        let validStatus = false;
        if (!codeStatus) {
          alert('入力されたコードが間違っています。');
        } else {
          if (codeStatus === '使用済') {
            alert ('入力されたコードは使用済みです。');
          } else if (codeStatus === '使用不可') {
            alert ('入力されたコードは使用できません。');
          } else {
            alert ('入力されたコードを使用します。');
            validStatus = true;
          }
        }
        return validStatus;
      },
      checkRequired: function () {
        let isFilled = true;
        if (this.code.length == 0
          || this.date.length == 0
          || this.time.length == 0
          || this.lastname.length == 0
          || this.firstname.length == 0
          || this.zip.length == 0
          || this.prefecture.length == 0
          || this.municipality.length == 0
          || this.address.length == 0
          || this.tel.length == 0) {
          alert('必須項目を入力してください')
          isFilled = false
        }
        return isFilled;
      },
      getTableSelect: function () {
        const Airtable = require('airtable');
        const base = new Airtable({apiKey: 'keyVGMwc0fL2nfNYU'}).base('appl2kR6WTnP2ccxI');
        const table = base('コード');
        const select = table.select({
          view: "Grid view",
          filterByFormula: "{コード}='"+this.code+"'"
        });
        return select;
      },
      isConfirmed: function () {
        var isConfirmed = false;
        if (window.confirm('以下の内容で送信してもよろしいですか？' + "\n"
          + 'コード: ' + this.code + "\n"
          + 'お届け希望日時: ' + this.date + " " + this.time + "\n"
          + 'お名前: ' + this.lastname + " " + this.firstname + "\n"
          + 'お届け先: 〒' + this.zip + " " + this.prefecture + this.municipality + this.address + " " + this.building + "\n"
          + '電話番号: ' + this.tel + "\n"
          + 'メールアドレス: ' + this.email)) {
            isConfirmed = true;
        }
        return isConfirmed;
      },
    },
  }
</script>

